/* ubuntu-regular - latin */
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/ubuntu-v14-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Ubuntu Regular'), local('Ubuntu-Regular'),
       url('/fonts/ubuntu-v14-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/fonts/ubuntu-v14-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('/fonts/ubuntu-v14-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('/fonts/ubuntu-v14-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/fonts/ubuntu-v14-latin-regular.svg#Ubuntu') format('svg'); /* Legacy iOS */
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Ubuntu';
    
}

.background {
    background: #333;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.logo img {
    position: absolute;
    width: 200px;
    left: 30px;
    top: 20px;
}

.icon {
    background: rgba(51, 51, 51, 0.5);
    padding-top: 20px;
    padding-bottom: 20px;
}

.icon img {
    width: 400px;
}

.bottom-left a {
    color: #aaaaaa;
    text-decoration: none;
    font-weight: 300;
    transition: 0.2s linear;
}

.bottom-left a:hover {
    color: white;
}

.bottom-left {
    position: absolute;
    bottom: 30px;
    left: 30px;
}

.bottom-right {
    position: absolute;
    right: 30px;
    bottom: 30px;
}

.link {
    display: inline-block;
}

.bottom-right p {
    color: #aaaaaa;
    margin: 0px;
    font-weight: 300;
}

.bottom-right a {
    color: #aaaaaa;
    text-decoration: none;
    margin-left: 30px;
    transition: 0.2s linear;
    font-weight: 300;
}

.bottom-right a:hover {
    color: white;
}

.contact-mail {
    display: inline-block;
}

.contact-phone {
    display: inline-block;
    margin-right: 30px;
}

.center {
    text-align: center;
}

.center h1,
.center h2 {
    color: white;
}

.center h1 {
    font-size: 45px;
    margin-bottom: 0px;
}

.center h2 {
    font-weight: 200;
    margin-top: 0px;
    font-size: 40px;
    line-height: 0.7;
}

.column {
    height: 100%;
}

.container {
    text-align: center;
    height: 100%;
}

.vertical-align-wrap {
    position: absolute;
    width: 100%;
    height: 100%;
    display: table;
}

.vertical-align {
    display: table-cell;
}

.vertical-align--middle {
    vertical-align: middle;
}

.slider {
    overflow-y: hidden;
    height: 100px;
    transition-property: all;
    transition-duration: .5s;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slider.closed {
    height: 0px;
}

.button {
    cursor: pointer;
}

.button p {
    transition: 0.2s linear;
}

.button p:hover {
    color: white;
}

#slider p {
    color: #aaaaaa;
}

@media only screen and (max-width: 700px) {
    .logo {
        text-align: center;
    }
    .logo img {
        position: relative;
        width: 200px;
        margin-top: 30px;
        left: 0px;
        top: 0px;
    }
    .center {
        margin-top: -135px;
    }
    .center h1 {
        font-size: 35px;
    }
    .center h2 {
        font-size: 30px;
    }
    .bottom-left {
        position: relative;
        margin-top: -55%;
        text-align: center;
        left: 0px;
        bottom: 0px;
    }
    .contact-mail {
        display: block;
    }
    .contact-phone {
        display: block;
        margin-right: 0px;
    }
    .bottom-right {
        position: relative;
        right: 0px;
        bottom: -15px;
        width: 100%;
        text-align: center;
        margin-bottom: 30px;
    }
    .link {
        display: block;
    }
    .bottom-right a {
        margin-left: 0px;
    }
}

@media only screen and (max-width: 540px) {
    .center h1 {
        font-size: 25px;
    }
    .center h2 {
        font-size: 20px;
    }
}

@media only screen and (max-width: 340px) {
    .center h1 {
        font-size: 21px;
    }
    .center h2 {
        font-size: 17px;
    }
}
@media only screen and (max-height: 400px) {
  .bottom-left {
        position: relative;
        margin-top: -15%;
        text-align: center;
        left: 0px;
        bottom: 0px;
    }
    .center {
        margin-top: -85px;
    }
}

.img-1 {
  background:url('/images/bg1.jpg') #333 no-repeat center center fixed;
  background-size: 100% 100%;
}